import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { getLanguage } from "../../utils"

const StyledNavigationWrapper = styled.div`
  margin-left: auto;
`

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;

  @media (max-width: 769px) {
    justify-content: center;
  }
`

const StyledListItem = styled.li`
  display: flex;
  margin: 0;
  padding: 0 1.5rem;

  @media (min-width: 769px) {
    &:last-child {
      padding-right: 0;
    }
  }
`

const sharedLinkStyle = css`
  color: #222221;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-weight: normal;

  transition: all 300ms linear;

  &:after {
    content: attr(data-text);
    content: attr(data-text) / "";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;

    @media speech {
      display: none;
    }
  }

  &:hover {
    font-weight: bold;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  ${sharedLinkStyle}
`

const StyledExternalLink = styled.a`
  ${sharedLinkStyle}
`

const Navigation = ({ footerItems }) => {
  return (
    <StyledNavigationWrapper>
      <nav>
        <StyledList>
          {footerItems
            .sort((a, b) => a.node.order - b.node.order)
            .filter(({ node }) => node.url !== "#")
            .map(({ node }) => {
              return (
                <StyledListItem key={node.contentful_id}>
                  {node.isExternal ? (
                    <StyledExternalLink
                      href={node.url}
                      data-text={node.title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {node.title}
                    </StyledExternalLink>
                  ) : (
                    <StyledLink
                      to={`/${getLanguage()}/${node.url}`}
                      data-text={node.title}
                    >
                      {node.title}
                    </StyledLink>
                  )}
                </StyledListItem>
              )
            })}
          <StyledListItem>
            <StyledExternalLink
              href="https://breville.eu"
              data-text="Breville.eu"
            >
              Breville.eu
            </StyledExternalLink>
          </StyledListItem>
        </StyledList>
      </nav>
    </StyledNavigationWrapper>
  )
}

Navigation.propTypes = {
  footerItems: PropTypes.array,
}

Navigation.defaultProps = {
  footerItems: [],
}
export default Navigation
