import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.$isRow &&
    `
      @media (min-width: 1024px) {
        flex-direction: row;
      }
    `};

  ${props =>
    props.$hasBottomMargin &&
    `
      margin-bottom: 3rem;

      @media (min-width: 769px) {
        margin-bottom: 7rem;
      }
    `};

  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 769px) {
    margin-bottom: 2rem;
  }
`

const Container = ({ children, isRow = false, hasBottomMargin = false }) => {
  return (
    <StyledContainer
      className="container"
      $isRow={isRow}
      $hasBottomMargin={hasBottomMargin}
    >
      {children}
    </StyledContainer>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  isRow: PropTypes.bool,
  hasBottomMargin: PropTypes.bool,
}

export default Container
