import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Navigation from "./navigation"
import Copyrights from "./copyrights"
import Container from "../../components/common/container"

const StyledFooter = styled.footer`
  position: relative;
  padding: 2.5rem 0;
  background: #cfcfcf;
  font-size: 1.1rem;
  margin-top: auto;
  z-index: 10;

  @media (max-width: 769px) {
    font-family: museo_sans300;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    padding: 1.5rem 0;

    .container {
      > p {
        order: 1;
        margin-top: 1rem;
      }
    }
  }
`

const Footer = ({ footerItems }) => (
  <StyledFooter>
    <Container isRow={true}>
      <Copyrights />
      <Navigation footerItems={footerItems} />
    </Container>
  </StyledFooter>
)

Navigation.propTypes = {
  footerItems: PropTypes.array,
}

export default Footer
