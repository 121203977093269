const TRANSLATIONS = {
  es: {
    "all-rights-reserved": "Todos los derechos reservados",
    recipes: "recetas",
    category: "categoría",
    products: "productos",
    "search-results": "resultados-de-la-búsqueda",
    "get-the-recipe": "Accede a la receta",
    "all-recipes": "Todas las recetas",
    "featured-products": "Productos destacados",
    "where-to-buy": "Dónde comprar",
    "where-to-buy-link": "dónde-comprar",
    "download-the-recipe": "Descarga la receta",
    "download-pdf": "Descargar como PDF",
    "enter-search-term": "Ingrese el término de búsqueda aquí…",
    "end-of-results": "Fin de búsqueda",
    "search-again": "Busca de nuevo",
    "not-found": "Página No Encontrada",
    all: "Todos",
    "all-2": "Todas",
    "404-info":
      "Lo sentimos, pero no pudimos encontrar la página que busca. Es posible que la dirección se haya escrito incorrectamente o que la página ya no exista.",
    "first-name": "Nombre",
    "last-name": "Apellidos",
    phone: "Número de teléfono",
    "e-mail": "Dirección de email",
    "address-1": "Dirección postal 1",
    "address-2": "Dirección postal 2",
    country: "País",
    city: "Ciudad",
    county: "Distrito",
    "postal-code": "Código postal",
    "date-code": "Código de fecha",
    "model-number": "Número de modelo",
    subject: "Asunto",
    message: "Mensaje",
    "attach-documents": "Adjuntar documentos",
    language: "Spanish",
    failed: "Algo ha fallado, por favor inténtelo más tarde",
    success:
      "Su mensaje ha sido enviado. Nos pondremos en contacto con usted en breves para dar respuesta a su mensaje.",
    "required-field": "Información requerida",
    "max-length": "Longitud máxima",
    "you-have": "Quedan",
    "characters-limit": "caracteres de un límite de 500 caracteres.",
    "code-info":
      "El código de fecha generalmente se encuentra en la etiqueta de clasificación. Ocasionalmente, esto se encuentra en otra parte del producto. Este código debe comenzar con una letra seguida de tres números. (por ejemplo]: A123)",
    "choose-file": "Elegir archivo",
    "attach-file": "Adjuntar archivo",
    submit: "Enviar",
    "upload-info": "Si es necesario, se puede adjuntar un documento o imagen",
    "upload-file-sizes":
      'El tamaño máximo de los archivos es de 3MB y el formato debe ser "pdf,txt,doc,docx,jpg,png,jpeg,gif"',
    "related-products": "Productos relacionados",
  },
  fr: {
    "all-rights-reserved": "Tous droits réservés",
    recipes: "recettes",
    category: "catégorie",
    products: "produits",
    "search-results": "résultats-de-recherche",
    "get-the-recipe": "Obtenir la recette",
    "all-recipes": "Toutes nos recettes",
    "featured-products": "Produits principaux",
    "where-to-buy": "Où acheter ?",
    "where-to-buy-link": "où-acheter?",
    "download-the-recipe": "Télécharger la recette",
    "download-pdf": "Télécharger en PDF",
    "enter-search-term": "Rechercher",
    "end-of-results": "Aucun résultat",
    "search-again": "Chercher à nouveau",
    "not-found": "Page non trouvée",
    all: "Tous",
    "all-2": "Toutes les",
    "404-info":
      "Désolé, nous ne parvenons pas à trouver la page que vous recherchez. Il se peut que l'adresse soit erronée, ou que la page n'existe plus. Vérifiez que vous avez tapé l'URL correctement.",
    "first-name": "Prénom",
    "last-name": "Nom",
    phone: "Téléphone",
    "e-mail": "Adresse e-mail",
    "address-1": "Adresse 1",
    "address-2": "Adresse 2",
    country: "Pays",
    city: "Ville",
    county: "Comté",
    "postal-code": "Code postal",
    "date-code": "Code de date",
    "model-number": "Modèle",
    subject: "Sujet",
    message: "Message",
    "attach-documents": "Joindre des documents",
    language: "French",
    failed: "Quelque chose s'est mal passé, veuillez réessayer plus tard.",
    success:
      "Votre message a été envoyé avec succès. Nous vous contacterons dans un court délai si votre message nécessite une réponse.",
    "required-field": "Le champ est obligatoire",
    "max-length": "Longueur maximale dépassée",
    "you-have": "Il vous reste ",
    "characters-limit": "caractères sur une limite de 500 caractères.",
    "code-info":
      "Le code de date se trouve généralement sur l'étiquette signalétique. Il peut également se trouver autre part sur le produit. Ce code doit commencer par une lettre, suivie de trois chiffres. (par exemple: A123)",
    "choose-file": "Choisir un fichier",
    "attach-file": "Joindre un fichier",
    submit: "Accepter",
    "upload-info":
      "Si nécessaire, vous pouvez joindre un document / une image.",
    "upload-file-sizes":
      'La taille du fichier ne doit pas dépasser 3 Mo et le format du fichier doit être "pdf, txt, doc, docx, jpg, png, jpeg, gif".',
    "related-products": "Produits apparentés",
  },
  de: {
    "all-rights-reserved": "Alle Rechte vorbehalten",
    recipes: "rezepte",
    category: "kategorie",
    products: "produkte",
    "search-results": "suchergebnisse",
    "get-the-recipe": "Das Rezept erhalten",
    "all-recipes": "Alle Rezepte",
    "featured-products": "vorgestellte Produkte",
    "where-to-buy": "Händlersuche",
    "where-to-buy-link": "händlersuche",
    "download-the-recipe": "Das Rezept downloaden",
    "download-pdf": "Als PDF herunterladen",
    "enter-search-term": "Suchbegriff hier eingeben...",
    "end-of-results": "Ende der Resultate",
    "search-again": "Erneut suchen",
    "not-found": "Seite nicht gefunden",
    all: "Alle",
    "404-info":
      "Es tut uns leid, aber wir konnten die Seite, die Sie suchen, nicht finden. Die Adresse wurde möglicherweise falsch eingegeben, oder die Seite existiert nicht mehr. Vergewissern Sie sich, dass Sie die URL richtig eingegeben haben.",
    "first-name": "Vorname",
    "last-name": "Nachname",
    phone: "Telefon",
    "e-mail": "E-Mail",
    "address-1": "Adresse",
    "address-2": "Adresszusatz",
    country: "Land",
    city: "Stadt",
    county: "Bundesland / Kanton",
    "postal-code": "PLZ",
    "date-code": "Datumscode",
    "model-number": "Produktmodell",
    subject: "Betreff",
    message: "Nachricht",
    "attach-documents": "Dokumente anhängen",
    language: "German",
    failed:
      "Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal.",
    success:
      "Ihre Nachricht wurde gesendet. Wir werden uns in Kürze bei Ihnen melden, wenn Ihre Nachricht eine Antwort erfordert.",
    "required-field": "Feld ist erforderlich.",
    "max-length": "Maximale Länge überschritten",
    "you-have": "Sie haben",
    "characters-limit": "Zeichen übrig von einem Limit von 500 Zeichen.",
    "code-info":
      "Der Datumscode befindet sich normalerweise auf dem Bewertungsetikett. Gelegentlich befindet sich dies an einer anderen Stelle des Produkts. Dieser Code sollte mit einem Buchstaben beginnen, gefolgt von drei Zahlen. (d.h. 1,2,3)",
    "choose-file": "Datei auswählen",
    "attach-file": "Datei anhängen",
    submit: "Einreichen",
    "upload-info": "Bei Bedarf können Sie ein Dokument / Bild anhängen.",
    "upload-file-sizes":
      'Die Dateigröße sollte 3 MB nicht überschreiten und das Dateiformat sollte sein "pdf,txt,doc,docx,jpg,png,jpeg,gif".',
    "related-products": "ähnliche Produkte",
  },
}

module.exports = {
  TRANSLATIONS,
}
