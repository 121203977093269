const devices = {
  fullHd: "1920px",
  desktopLarge: "1480px",
  desktopMedium: "1280px",
  desktop: "1024px",
  tablet: "1023px",
  mobile: "769px",
  mobileBig: "600px",
  mobileXs: "376px",
}

export default devices
