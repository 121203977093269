import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SearchInput from "../../components/common/search-input"

const StyledSearch = styled.div`
  position: relative;
  top: 0.1rem;
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    position: absolute;
    top: 0;
    right: 4.5rem;
  }
`

const StyledSearchButton = styled.button`
  position: relative;
  z-index: 10;
  background: none;
  padding: 0;
  border: 0;
  color: #222221;
  font-size: 2rem;
  text-decoration: none;
  transition: 300ms linear;
  cursor: pointer;
  margin-left: 2rem;

  svg {
    position: relative;
    top: 0.1rem;
    width: 100%;
    max-width: 1.8rem;

    path {
      fill: #000;
    }
  }

  &:hover path {
    fill: #b5161b;
  }

  @media (max-width: 1023px) {
    height: 3.8rem;
    width: 3.8rem;
    font-size: 1.5rem;
    padding-top: 0.2rem;
    margin-left: 0;

    ${props =>
      props.$isOpen &&
      `
        background-color: #fff;
      `};
  }
`

const StyledSearchWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: ${props => (props.$isOpen ? "1500px" : "0")};
  height: 100%;
  background: rgba(207, 207, 207, 0.7);
  z-index: 3;
  transition: 200ms linear;
`

const Search = ({ onSearchOpen, onSearchClose }) => {
  const searchTerm =
    typeof document !== "undefined"
      ? new URL(document?.location).searchParams.get("search")
      : ""
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const toggleSearch = () => {
    if (isSearchOpen) {
      onSearchClose()
    } else {
      onSearchOpen()
    }

    setIsSearchOpen(!isSearchOpen)
  }

  const closeSearch = useCallback(() => {
    onSearchClose()
    setIsSearchOpen(false)
  }, [onSearchClose])

  return (
    <StyledSearch>
      <StyledSearchButton onClick={toggleSearch} $isOpen={isSearchOpen}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" />
        </svg>
      </StyledSearchButton>
      <SearchInput
        isSearchOpen={isSearchOpen}
        defaultSearchTerm={searchTerm ? searchTerm : ""}
        onSearchClose={closeSearch}
      />
      <StyledSearchWrapper $isOpen={isSearchOpen} onClick={closeSearch} />
    </StyledSearch>
  )
}

Search.propTypes = {
  onSearchOpen: PropTypes.func,
  onSearchClose: PropTypes.func,
}

Search.defaultProps = {
  onSearchOpen: () => {},
  onSearchClose: () => {},
}

export default Search
